<template>
	<div v-if="(!conditionalDisplay.isMobileApp && canDownload) || (conditionalDisplay.isMobileApp && model.length === 1 && model[0].is_document && canDownload)">
		<v-flex v-if="simpleButtonDisplay && !listTileDisplay" shrink>
			<w-btn :flat="flat" icon="get_app" :loading="isLoading" :disabled="isLoading" mini v-bind="$attrs" @click="download()"> {{ $t('ecm.btn_download') }}</w-btn>
		</v-flex>
		<v-list-tile v-else-if="listTileDisplay" avatar @click="download()">
			<v-list-tile-avatar>
				<v-progress-circular v-if="isLoading" color="primary" indeterminate />
				<v-icon v-else color="primary">get_app</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'actions.download'" />
			</v-list-tile-content>
		</v-list-tile>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import DocumentActionModuleGuard from '@/mixins/ModulesGuards/Documents/Actions/DocumentActionModuleGuard'
import DocumentArchiveService from '@/services/Documents/DocumentArchiveService'

export default {
	name: 'DownloadButton',
	mixins: [DocumentActionModuleGuard],
	props: {
		version: {
			type: [Object, Array],
			required: false,
			default: null
		},
		value: {
			type: [Array, Object],
			required: false,
			default: () => []
		},
		flat: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data: function () {
		return {
			isLoading: false
		}
	},
	computed: {
		...mapState({
			treeStructureId: state => state.documents.treeStructure.tree_structure_id,
			authToken: state => state.auth.token
		}),
		model: {
			get: function () {
				if (!this.value) {
					return []
				}
				return Array.isArray(this.value) ? this.value : [this.value]
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		canDownload: function () {
			return this.model && this.model.length > 0 && this.model.every(node => !node.is_trash)
		},
		isSingleDocumentDownload() {
			return this.model.length === 1 && this.model[0].is_document
		}
	},
	methods: {
		async download() {
			if (this.isLoading) {
				return;
			}

			this.isLoading = true
			try {
				if (this.isSingleDocumentDownload) {
					await this.downloadSingleDocument()
				} else {
					await this.downloadMultipleDocuments()
				}
			} finally {
				this.isLoading = false
			}
		},

		triggerDownload(url) {
			const a = window.document.createElement('a')
			a.style = 'display:none'
			a.target = '_blank'
			a.href = url
			a.click()
			a.remove()
		},

		async downloadSingleDocument() {
			const consideredVersion = this.version || this.model[0].latestVersion
			const url = new URL(consideredVersion.uri)
			url.searchParams.set('download', true)

			this.triggerDownload(url.href)

			this.eventBus.emit(this.events.DOCUMENT_DOWNLOADED, this.model[0])
		},

		async downloadMultipleDocuments() {
			const { documents, folders } = this.getDocumentsAndFolders()

			const { downloadUrl } = await DocumentArchiveService.createDocumentArchive(this.vendorId, null, {
				tree_structure_id: this.treeStructureId,
				documents,
				folders
			})

			this.model.forEach(node => {
				this.eventBus.emit(this.events.DOCUMENT_DOWNLOADED, node)
			})

			if (downloadUrl) {
				this.triggerDownload(downloadUrl)
				return
			}

			this.appEventBus.emit(
				this.appEvents.SNACKBAR_SUCCESS,
				this.$tc('documents.archive.delayed', this.model.length)
			)

		},

		getDocumentsAndFolders() {
			const documents = []
			const folders = []

			this.model.forEach(node => {
				if (node.is_folder && !node.is_trash) {
					folders.push(node.id)
				} else if (node.is_document) {
					documents.push(node.id)
				}
			})

			return { documents, folders }
		},
	}
}
</script>
